<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="reppath" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" draggable=".p-1" :delay="100" :resizable="true" :width="575" height="auto" focusTrap="true" @opened="afterfilterwindow_visible">
          <form class="p-1">
            <h4 >Report Options</h4>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Subject</label>
              <div class="col-md-10">
                <select  ref="cmbSubject" class="form-control" v-model="subject_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in subjects" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Department</label>
              <div class="col-md-10">
                <select  ref="cmbDepartment" class="form-control" v-model="department_id">
                  <option value="0" selected>All</option>
                  <option v-for="author in authors" :value="author.id">{{author.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Section</label>
              <div class="col-md-10">
                <select  ref="cmbSection" class="form-control" v-model="section_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in sections" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row align-self-end">
              <div class="col-md-12 text-right">
                <button class="btn btn-outline-success" @click="loadData">Show Report <i class="icon-printer ml-2" style="color: green;"></i> </button>
              </div>

            </div>

          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'

//https://euvl.github.io/vue-js-modal/

export default {
  name: 'StudentList',
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'student_list.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      departments : [],
      sections : [],
      subjects : [],
      department_id : 0,
      section_id : 0,
      subject_id : 0,
    }
  },
  mounted() {
    const self = this;
    self.$modal.show('filter_window');
    self.$data.jsondata = {"ok": true,"status": 200,"data": []};
    self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/reports/student`;
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$data.reppath = '';
      this.$refs.cmbSubject.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      try {
        self.$data.departments = [];
        // alert(`${process.env.VUE_APP_ROOT_API}api/lms/departments`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.departments = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {

        });

        self.$data.subjects = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.subjects = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });
      }catch (e) {
        alert(e);
      }
    },
    loadData(){
      const self = this;

      self.$modal.hide('filter_window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/student?from=${self.$data.from_date}&upto=${self.$data.upto_date}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.jsondata = resp;
            self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/reports/student?from=${self.$data.from_date}&upto=${self.$data.upto_date}`;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>

</style>
