import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=423e78e1&scoped=true&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423e78e1",
  null
  
)

export default component.exports